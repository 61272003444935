import { STRAPI, STRAPI_token } from '../../utils/config';
import axios from 'axios';
import qs from 'qs';

interface UserInfo {
    jwt: string;
    user: any; 
}

interface DBResult {
    info: UserInfo;
    pageMeta?: any;
}

export const DBLogin = async (identifier:string,password:string): Promise<DBResult> => {   
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${STRAPI_token}`
            },
        };
        const url = `${STRAPI}/api/auth/local`;
        const response = await axios.post(`${STRAPI}/api/auth/local`, {
            identifier,
            password,
        },config);

        return {info:response.data};

    } catch (err) {
        console.error('Error setting up sync with remote database:', err);
        throw new Error('同步設置失敗');
    }
};