import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { Container, Col, Card, Row, Button } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DBsearch } from '../../api/DBsearch';
import { DBupdate } from '../../api/DBupdate';
import Swal from 'sweetalert2';
import { DBinsert } from '../../api/DBinsert';


interface DataState {
  chName: string;
  enName: string;
  display: boolean;
  chContent: any; 
  enContent: any;
}

const htmlToStrapi = (htmlContent: string): any[] => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const blocks = Array.from(doc.body.childNodes).map(node => {
      return {
        type: 'paragraph',
        children: [{ text: node.textContent || '',type:"text" }]
      };
    });
    return blocks;
};

const strapiToHtml = (strapiContent: any[]): string => {
    return strapiContent.map(block => {
        const text = block.children.map((child: any) => child.text).join('');
        return `<p>${text}</p>`;
    }).join('');
};
const BackAboutEdit: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [chineseContent, setChineseContent] = useState('');
  const [englishContent, setEnglishContent] = useState('');
  const [data, setData] = useState<DataState>({ chName: '', enName: '',display:true, chContent: [], enContent: [] });

  const { state } = location as any;
  const id = state?.id;

  const handleBack = () => {
    navigate('/dashboard/backAbout');
  }

  const showInfo = async (id: number) => {
    try {
      const searchStr = { id: { type: 'basic', operator: 'eq', text: id } };
      const res: any = await DBsearch('introduces', 'populate=*', searchStr);
      const { title_zh, title_en, content_en, content_zh,display } = res.info[0].attributes;
      setData({ chName: title_zh, enName: title_en, display:display, chContent: content_zh, enContent: content_en });

      // Convert Strapi content to HTML
      const chContentHtml = strapiToHtml(content_zh);
      const enContentHtml = strapiToHtml(content_en);

      setChineseContent(chContentHtml);
      setEnglishContent(enContentHtml);
    } catch (error) {
      console.error('Fetching product name failed:', error);
    }
  }

  const handleSubmit = async() => {
    const chContentBlocks = htmlToStrapi(chineseContent);
    const enContentBlocks = htmlToStrapi(englishContent);
    let err=0;

    if(data.chName==="" || data.enName===""){
      err++;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "必填項目未填寫。",
        showConfirmButton: false,
        timer: 1500
      });
    }
    if(err===0){
      if(id){
        //更新
        const updateStr = {
            data: {
                title_zh: data.chName,
                title_en: data.enName,
                display: data.display,
                content_zh: chContentBlocks,
                content_en: enContentBlocks
            }
        }
        const res = await DBupdate('introduces', id, updateStr);
        if(res.info===200){
            //回到backAbout & 呈現修改成功
            Swal.fire({
            icon: "success",
            title: "更新成功",
            showConfirmButton: false,
            timer: 1500
            });
            navigate('/dashboard/backAbout');
        }
      }else{
        const insertStr = {
            data: {
                title_zh: data.chName,
                title_en: data.enName,
                display: data.display,
                content_zh: chContentBlocks,
                content_en: enContentBlocks
            }
        }
        const res = await DBinsert('introduces', insertStr);
        if(res.status===200){
            //回到backAbout & 呈現修改成功
            Swal.fire({
            icon: "success",
            title: "新增成功",
            showConfirmButton: false,
            timer: 1500
            });
            navigate('/dashboard/backAbout');
        }
      }
    }
    
   
  }

  useEffect(() => {
    if (id) {
      showInfo(id);
    }
  }, [id]);

  return (
    <>
      <Container fluid>
        <Col xs={12} lg={12}>
          <h4 className='text-secondary'><b>關於我們 / {id ? '編輯頁面' : '新增頁面'}</b></h4>
        </Col>
      </Container>
      <Container className='pt-2' fluid>
        <Card className='border shadow-sm bg-light'>
          <Card.Body>
            <Row>
              <Col xs={6}>
                <a role='button' onClick={() => handleBack()}>
                  <IoIosArrowDropleftCircle size={30} style={{ color: 'rgba(0,0,0,0.3)' }} />
                </a>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col xs={6}>
                <div className="mb-3 position-relative">
                  <label className="form-label text-dark">
                    <b>中文名稱 *</b>
                  </label>
                  <input type='text' className="form-control" placeholder="請輸入中文名稱" value={data.chName} onChange={(e) => setData({...data, chName: e.target.value})} />
                </div>
              </Col>
              <Col xs={6}>
                <div className="mb-3 position-relative">
                  <label className="form-label text-dark">
                    <b>英文名稱 *</b>
                  </label>
                  <input type='text' className="form-control" placeholder="請輸入英文名稱" value={data.enName} onChange={(e) => setData({...data, enName: e.target.value})} />
                </div>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col xs={12}>
                <div className="mb-3 position-relative">
                  <label className="form-label text-dark">
                    <b>內容呈現</b>
                  </label>
                  <select className="form-select" value={data.display.toString()} onChange={(e) => setData({ ...data, display: e.target.value === 'true' })}>
                    <option value="true">呈現</option>
                    <option value="false">隱藏</option>
                  </select>
                </div>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col xs={6}>
                <div className="mb-3 position-relative">
                  <label className="form-label text-dark">
                    <b>中文內容</b>
                  </label>
                  <div className="custom-quill">
                    <ReactQuill value={chineseContent} onChange={setChineseContent} modules={{ toolbar: false }} />
                  </div>
                </div>
              </Col>
              <Col xs={6}>
                <div className="mb-3 position-relative">
                  <label className="form-label text-dark">
                    <b>英文內容</b>
                  </label>
                  <div className="custom-quill">
                    <ReactQuill value={englishContent} onChange={setEnglishContent} modules={{ toolbar: false }} />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className='text-center mt-3 mb-4'>
                <Button variant="outline-dark" className='px-4' onClick={handleSubmit}>
                  &emsp;送&ensp;出 &emsp;
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default BackAboutEdit;
