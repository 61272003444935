import { STRAPI, STRAPI_token } from '../../utils/config';
import axios from 'axios';

export const DBuploadDelMulti = async (fileIds:any) => {
  try {

    let uploadStatus=0;
    let uploadId='';
    for (const id of fileIds) {
      const delInfo = await axios.delete(`${STRAPI}/api/upload/files/${id}`, {
        headers: {
          Authorization: `Bearer ${STRAPI_token}`,
        },
      });

      if (delInfo.status !== 200) {
        uploadStatus++;
        uploadId=` ${id} `;
        throw new Error(`檔案刪除失敗: ${id}`);
      }
    }

    if (uploadStatus==0) {
      return { status:true };
    } else {
        return { status:false,info:"檔案刪除失敗"+uploadId };
    }
    
  } catch (error:any) {
    console.error('Error:', error.message);
    return { status: false, message: error.message };
  }
};