import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
      translation: {
        "Home": "Home",
        "About Us": "About Us",
        "Product": "Product",
        "Contact Us": "Contact Us",
        "Download": "Download",
        "Language": "中文",
        "index intro":"We adhere to the corporate philosophy of integrity, quality, innovation, and mutual benefit. With our in-depth research and continuous innovation in metal processing and precision carbide tools (including a diverse range of precision components such as drills, milling cutters, cutting, and grinding tools), we possess extensive design experience and strong technical expertise.",
        "Integrity": "Integrity",
        "Excellence": "Excellence",
        "Innovation": "Innovation",
        "Mutual Benefit": "Mutual Benefit",
        "Company Introduction Video": "Company Introduction Video",
        "Exhibition Video": "Exhibition Video",
        "copyText": "Click to copy",
        "Address": "Address",
        "AddressInfo": "11F., No.31-40, Sec. 2, Jinan Rd.,Zhongzheng Dist., Taipei City 100023, Taiwan",
        "Phone": "Phone",
        "Fax": "Fax",
        "Email": "Email",
        "Contact Title": "Send the mail to us ....",
        "Your Name": "Your Name",
        "Your Email": "Your Email",
        "Subject": "Subject",
        "Message": "Message",
        "send": "Send",
        "clear": "Clear",
        "about intro1":"We adhere to 'Quality First', focusing on high-end metal parts design and development.",
        "about intro2":"Our products, backed by professional technology and advanced equipment, earn clients trust and recognition."
      }
    },
    zh: {
      translation: {
        "Home": "首頁",
        "About Us": "關於我們",
        "Product": "產品介紹",
        "Contact Us": "聯繫我們",
        "Download": "下載",
        "Language": "English",
        "index intro":"我們秉持著誠信、優質、創新、互利企業理念，憑藉著對金屬加工與硬質合金精密刀具（涵蓋鑽頭、銑刀、切削及磨削工具等多樣化的精密配件）的深入研究與不斷創新，並且擁有豐富的設計經驗與精湛的技術力量。",
        "Integrity": "誠信",
        "Excellence": "優質",
        "Innovation": "創新",
        "Mutual Benefit": "互利",
        "Company Introduction Video": "公司介紹影片",
        "Exhibition Video": "展覽會影片",
        "copyText": "點選複製",
        "Address": "地址",
        "AddressInfo": "100023 臺北市中正區濟南路二段31之40號11樓",
        "Phone": "電話",
        "Fax": "傳真",
        "Email": "電子郵件",
        "Contact Title": "發送郵件給我們....",
        "Your Name": "您的姓名",
        "Your Email": "您的電子郵件",
        "Subject": "主題",
        "Message": "訊息",
        "send": "發送",
        "clear": "清除",
        "about intro1":"我們始終堅持『品質第一』，致力於高端金屬加工零件的設計與研發。",
        "about intro2":"打造卓越產品，以專業技術和先進設備贏得國內外市場的信任與肯定。"
      }
    }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", //default 
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
