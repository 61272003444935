import React, { useEffect, useState } from 'react';
import NavbarFront from '../../components/common/NavbarFront';
import HeaderFront from '../../components/common/HeaderFront';
import FooterFront from '../../components/common/FooterFront';
import { Nav, Col, Row, Container, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DBsearch } from '../../api/DBsearch';
import { STRAPI } from '../../utils/config';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';

const About: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState<any>([]);

  const showData = async () => {
    try {
      const searchStr = { nonDel: { type: 'basic', operator: 'eq', text: true },display:{type: 'basic', operator: 'eq', text: true} };
      const res = await DBsearch('introduces', 'populate=*', searchStr, 'sort_ASC');
      if (res.info.length > 0) {
        setData(res.info);
      }
    } catch (error) {
      console.error('Fetching product name failed:', error);
      return null;
    }
  }

  useEffect(() => {
    showData();
  }, []);

  return (
    <>
      <NavbarFront />
      <HeaderFront />

      <Container>
        <Tab.Container defaultActiveKey="home">
          <Row className="justify-content-center p-3 mt-4 mb-3">
            <Col>
              <Nav variant="pills" className="flex-row justify-content-center nav-horizontal">
                {data.length > 0 && data.map((info: any, index: number) => {
                  const { title_en, title_zh } = info.attributes;
                  let navKey = "";
                  if (index === 0) {
                    navKey = "home";
                  } else {
                    navKey = "menu" + info.id;
                  }
                  return (
                    <React.Fragment key={index}>
                      <Nav.Item>
                        <Nav.Link className='nav-titleLink mt-2 mb-2 px-5' eventKey={`${navKey}`}><b>{i18n.language === 'en' ? title_en : title_zh}</b></Nav.Link>
                      </Nav.Item>&emsp;&emsp;
                    </React.Fragment>
                  );
                })}
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col>
              <Tab.Content>
                {data && data.map((info: any, index: number) => {
                  const { title_en, title_zh, content_en, content_zh, image } = info.attributes;
                  let navKey = "";
                  if (index === 0) {
                    navKey = "home";
                  } else {
                    navKey = "menu" + info.id;
                  }

                  const content = i18n.language === 'en' ? content_en : content_zh;
                  const hasImages = image.data && image.data.length > 0;
                  
                  return (
                    <Tab.Pane eventKey={`${navKey}`} key={index}>
                      <Container>
                        <Row className='mb-5'>
                          {hasImages && (
                            <Col md={6} className='mt-4'>
                              <Row>
                                <Col>
                                  <div className='shadow'>
                                    {image.data.length > 1 ? (
                                      <Swiper
                                        pagination={{ clickable: true }}
                                        autoplay={{ delay: 2500, disableOnInteraction: false }}
                                        modules={[Pagination, Autoplay]}
                                        className="mySwiper"
                                      >
                                        {image.data.map((item: any, index: number) => {
                                          const url=item.attributes.url;
                                          //const url=item.attributes.formats.medium.url;
                                          const imgUrl = STRAPI + url;
                                          return (
                                            <SwiperSlide key={index}>
                                              <img src={imgUrl} alt="捷和實業有限公司" className='img-fluid' />
                                            </SwiperSlide>
                                          );
                                        })}
                                      </Swiper>
                                    ) : (
                                      <Swiper className="mySwiper">
                                        <SwiperSlide>
                                          {(() => {
                                            const imgUrl = STRAPI + image.data[0].attributes.url;
                                            return <img src={imgUrl} alt="捷和實業有限公司" className='img-fluid' />;
                                          })()}
                                        </SwiperSlide>
                                      </Swiper>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          )}
                          <Col md={hasImages ? 6 : 12}>
                            <h1 className='about-title mb-3'><b>{i18n.language === 'en' ? title_en : title_zh}</b></h1>
                            {content.map((item: any, index: number) => (
                              <p key={index}>{item.children[0].text}</p>
                            ))}
                          </Col>
                        </Row>
                      </Container>
                    </Tab.Pane>
                  );
                })}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
      <Container fluid>
        <Row className='align-items-center p-5 text-center text-white' style={{ background: 'linear-gradient(100deg, #638b95, #f4711f, #cccccc)', backgroundColor: '#eb5d1e' }}>
          <div className='p-5'>
            <h3>{t('about intro1')}</h3>
            <h3>{t('about intro2')}</h3>
          </div>
        </Row>
      </Container>
      <FooterFront />
    </>
  );
}

export default About;
