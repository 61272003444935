import { STRAPI, STRAPI_token } from '../../utils/config';
import axios from 'axios';
import qs from 'qs';


interface DBResult {
    info: any;
}

export const DBchkPwd = async (pwd:any,jwt:any): Promise<DBResult> => {   
    try {
        
        const response = await axios.post(`${STRAPI}/api/auth/change-password`, {
            currentPassword: pwd.oldPwd,
            password: pwd.newPwd,
            passwordConfirmation: pwd.confirmPwd,
        }, {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
        });

        return {info:response};

    } catch (err) {
        console.error('Error setting up sync with remote database:', err);
        throw new Error('同步設置失敗');
    }
};