import React,{ useEffect,useState } from 'react';
import NavbarFront from '../../components/common/NavbarFront';
import HeaderFront from '../../components/common/HeaderFront';
import { DBsearch } from '../../api/DBsearch';
import { STRAPI } from '../../utils/config';
import { Container, Row, Col, Button } from 'react-bootstrap';
import FooterFront from '../../components/common/FooterFront';

import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { useTranslation } from 'react-i18next';

/*import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
*/

const Download:React.FC = () => {
  const { i18n } = useTranslation();

  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  /*const [uptime, setUptime] = useState<string>('');
  const [file, setFile] = useState<string>('');*/

  const [uptime, setUptime] = useState<{zhTime:string,enTime:string}>({zhTime:'',enTime:''});
  const [file, setFile] = useState<{zhFile:string,enFile:string}>({zhFile:'',enFile:''});

  const showInfo = async ()=>{
    try {
      const res:any = await DBsearch('pdf-download', 'populate=*');
      let file="",formattedUptime;
      let fileEN="",formattedUptimeEN;
      if(res.info.attributes.pdfFile){
        file=STRAPI+res.info.attributes.pdfFile.data.attributes.url;
        formattedUptime = res.info.attributes.pdfFile.data.attributes.updatedAt.split('T')[0].replace(/-/g, '/');
      }
      if(res.info.attributes.pdfFile_en){
        fileEN=STRAPI+res.info.attributes.pdfFile_en.data.attributes.url;
        formattedUptimeEN = res.info.attributes.pdfFile_en.data.attributes.updatedAt.split('T')[0].replace(/-/g, '/');
      }

      setUptime({zhTime:formattedUptime,enTime:formattedUptimeEN});
      setFile({zhFile:file,enFile:fileEN});
      
    } catch (error) {
        console.error('Fetching product name failed:', error);
        return null;
    }
  }

  const downloadPDF = () => {
    const pdfFile = i18n.language === 'zh' ? file.zhFile : file.enFile;
    if (pdfFile) {
      window.open(pdfFile, "_blank");
    }
  }

  useEffect(() => {
    showInfo();
  }, []);

    return(
        <>
          <NavbarFront />
          <HeaderFront />
          <Container>
            <Row className='mt-3'>
              <Col sm={12} md={8}>
                {
                  i18n.language==="zh" ?(
                    <span className='text-secondary'>更新時間：{uptime.zhTime}</span>
                  ):(
                    <span className='text-secondary'>更新時間：{uptime.enTime}</span>
                  )
                }
              </Col>
              <Col sm={12} md={4} className="d-flex justify-content-md-end align-items-center">
                <Button variant="outline-dark" className="btn-sm-print" onClick={()=>downloadPDF()}>Download or Print PDF</Button>
              </Col>
            </Row>

            <Row className='mt-2 pb-5'>
              <Col className='col-md-12 mb-2'>
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                    <div className="d-flex justify-content-end bg-default mb-3 shadow-sm border">
                        <div className="p-2"><ZoomInButton /></div>
                        <div className="p-2"><ZoomOutButton /></div>
                        <div className="p-2 bg-warning"><ZoomPopover /></div>
                    </div>
                    <div className='shadow-sm border'>
                      <div className='pdf-size'>
                        {i18n.language === "zh" && file.zhFile && <Viewer fileUrl={file.zhFile} plugins={[zoomPluginInstance]} />}
                        {i18n.language === "en" && file.enFile && <Viewer fileUrl={file.enFile} plugins={[zoomPluginInstance]} />}
                      </div>
                    </div> 
                  </Worker>
                </Col>
            </Row>

          </Container>
          <FooterFront />
        </>
    );
}

export default Download