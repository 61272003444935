import { STRAPI, STRAPI_token } from '../utils/config';
import axios from 'axios';
import qs from 'qs';
import { transformKeys } from '../utils/transofrmKeys';

interface DBResult {
    info: any[];
    pageMeta?: any;
    url?:any;
}

export const DBsearch = async (DBname:string,populateConn?:string,searchStr?:any,type?:string): Promise<DBResult> => {   
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${STRAPI_token}`
            }
        };
        let query="";
        if(type==="search_id_ASC"){
            query = qs.stringify({
                filters: transformKeys(searchStr,DBname).transformed,
                sort:['id:asc'],
            }, {
                encodeValuesOnly: true, 
            });
        }else if(type==="sort_ASC"){
            query = qs.stringify({
                filters: transformKeys(searchStr,DBname).transformed,
                sort:['sort:asc','id:asc'],
            }, {
                encodeValuesOnly: true, 
            });
        }else{
            query = qs.stringify({
                filters: transformKeys(searchStr,DBname).transformed,
                sort:['id:desc'],
            }, {
                encodeValuesOnly: true, 
            });
        }
        

        let url;
        if(populateConn){
            url=`${STRAPI}/api/${DBname}?${query}&${populateConn}`;

        }else{
            url=`${STRAPI}/api/${DBname}?${query}`;
        }
        const response = await axios.get(url,config);

        return {info:response.data.data,pageMeta:response.data.meta};
    } catch (err) {
        console.error('Error setting up sync with remote database:', err);
        throw new Error('同步設置失敗');
    }
};