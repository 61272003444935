import React, { useState, useEffect } from 'react';
import { Container, Col, Card, Row, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { DBsearch } from '../../api/DBsearch';
import { DBupdate } from '../../api/DBupdate';
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import { dataTableLang } from '../../utils/dataTableLang';
import { DBuploadDel } from '../../api/back/DBuploadDel';

interface InfoFormat {
  id: number;
  attributes: {
    name_en: string;
    name_zh: string;
    display: boolean;
    sort: number;
    image: {
      data: { id: number };
    } | null;
  };
}

const BackPinfo: React.FC = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<InfoFormat[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [category, setCategoryData] = useState<InfoFormat[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [searchName, setSearchName] = useState<string>('');

  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCategoryId(event.target.value);
  };

  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(event.target.value);
  };

  const handleSearchNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(event.target.value);
  };

  const showCategory = async () => {
    try {
      const searchStr = { nonDel: { type: 'basic', operator: 'eq', text: true } };
      const res = await DBsearch('systems', 'populate=*', searchStr, 'sort_ASC');
      if (res.info.length > 0) {
        setCategoryData(res.info);
      }
    } catch (error) {
      console.error('Fetching categories failed:', error);
      return null;
    }
  };

  const showData = async () => {
    try {
      const searchStr: any = { nonDel: { type: 'basic', operator: 'eq', text: true } };

      if (selectedCategoryId && selectedCategoryId!=="none") {
        searchStr.system={type: 'advance', operator: 'eq', text: selectedCategoryId ,other:["system","name_en"]};
      }

      if (selectedStatus && selectedStatus!=="none") {
        searchStr.display = { type: 'basic', operator: 'eq', text: selectedStatus };
      }

      if (searchName) {
        searchStr.name_zh = { type: 'basic', operator: 'contains', text: searchName };
        searchStr.name_en = { type: 'basic', operator: 'contains', text: searchName };
      }


      const res = await DBsearch('products', 'populate=*', searchStr);
      if (res.info.length > 0) {
        setData(res.info);
        setLoading(false);
      }else{
        setData([]);
        setLoading(false);
      }
    } catch (error) {
      console.error('Fetching product data failed:', error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const editMode = (id?: number) => {
    if (id) {
      navigate(`/dashboard/backPEdit/`, { state: { id, dbName: 'products' } });
    } else {
      navigate(`/dashboard/backPEdit/`, { state: { dbName: 'products' } });
    }
  };

  const editSort = () => {
    navigate(`/dashboard/backPsort/`);
  };

  const deleteMode = async (id: number, imgId?: number, fileId?: number, fileEnId?:number) => {
    Swal.fire({
      title: '您確定要刪除這個項目嗎？',
      text: "此操作無法撤銷！",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '是的，刪除它！',
      cancelButtonText: '取消'
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (imgId) {
          const deleteRes = await DBuploadDel(imgId);
          if (!deleteRes.status) {
            throw new Error(deleteRes.message);
          }
        }

        if (fileId) {
          const deleteRes = await DBuploadDel(fileId);
          if (!deleteRes.status) {
            throw new Error(deleteRes.message);
          }
        }

        if (fileEnId) {
          const deleteRes = await DBuploadDel(fileEnId);
          if (!deleteRes.status) {
            throw new Error(deleteRes.message);
          }
        }

        const updateStr = {
          data: {
            nonDel: false
          }
        };
        const res = await DBupdate('products', id, updateStr);
        if (res.info === 200) {
          Swal.fire({
            icon: "success",
            title: "更新成功",
            showConfirmButton: false,
            timer: 1500
          });
          showData();
        }
      }
    });
  };

  useEffect(() => {
    showData();
    showCategory();
  }, []);

  useEffect(() => {
    if (!loading && data.length>0) {
      const table = $('#example_table').DataTable({
        ordering: false,
        language: dataTableLang,
        searching: false,
      });

      $('div.dt-length').hide();

      return () => {
        table.destroy();
      };
    }
  }, [loading, setData]);

  return (
    <>
      <Container fluid>
        <Col xs={12} lg={2}>
          <h4 className='text-secondary'><b>產品資料</b></h4>
        </Col>
      </Container>
      <Container className='pt-2' fluid>
        <Card className='border shadow-sm bg-light'>
          <Card.Body>
            <Row>
              <Col xs={6}>
                <Button variant='outline-dark' className="px-4" onClick={() => editMode()}>+&ensp;新增產品資料</Button>
              </Col>
              <Col xs={6} className='text-end'>
                <Button variant='warning' className="px-4" onClick={() => editSort()}><i className="bi bi-arrows-expand"></i>選擇產品排序</Button>
              </Col>
            </Row>
            <Row className='pt-3 text-secondary'>
              <span>進階搜尋 -</span>
              <Col xs={3}>
                <Form.Select value={selectedCategoryId ?? ''} onChange={handleCategoryChange}>
                  <option value="none">選擇類別</option>
                  {category.map(cat => (
                    <option key={cat.id} value={cat.attributes.name_en}>{cat.attributes.name_zh}</option>
                  ))}
                </Form.Select>
              </Col>
              <Col xs={3}>
                <Form.Select value={selectedStatus ?? ''} onChange={handleStatusChange}>
                  <option value="none">選擇狀態</option>
                  <option value="true">開啟</option>
                  <option value="false">關閉</option>
                </Form.Select>
              </Col>
              <Col xs={4}>
                <Form.Control 
                  type="text" 
                  placeholder="輸入產品名稱" 
                  value={searchName} 
                  onChange={handleSearchNameChange}
                />
              </Col>
              <Col xs={2}>
                <Button variant='secondary' className='px-4' onClick={showData}><i className="bi bi-search"></i>&ensp;搜尋</Button>
              </Col>
            </Row>
            <Row className='text-dark'>
              <Col xs={12}>
                <table id="example_table" className="table table-bordered " style={{ width: "100%" }}>
                  <thead>
                    <tr className='table-dark'>
                      <th>類別</th>
                      <th>中文名稱</th>
                      <th>英文名稱</th>
                      <th className='text-center'>呈現狀態</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading && data.length > 0 && data.map((info: any, index: number) => {
                      const id = info.id;
                      const { system, name_zh, name_en, display, image, files, files_en } = info.attributes;
                      let imgId: any;
                      let fileId: any;
                      let fileEnId: any;
                      if (image?.data) {
                        imgId = image.data.id;
                      }

                      if (files?.data) {
                        fileId = files.data.id;
                      }

                      if (files_en?.data) {
                        fileEnId = files_en.data.id;
                      }

                      return (
                        <tr key={index}>
                          <td>{system.data && system.data.attributes.name_zh ? system.data.attributes.name_zh : "(請選擇類別)"}</td>
                          <td>{name_zh}</td>
                          <td>{name_en}</td>
                          <td className={`text-center ${display ? 'text-dark' : 'text-danger'}`}>
                            {display ? "開啟" : "關閉"}
                          </td>
                          <td className='text-center'>
                            <Button variant='outline-primary' className="mx-lg-1 mx-md-3" onClick={() => editMode(id)}>
                              <i className="bi bi-highlighter"></i>
                            </Button>
                            <Button variant='outline-danger' className="mx-lg-1 mx-md-3" onClick={() => deleteMode(id, imgId, fileId, fileEnId)}>
                              <i className="bi bi-trash"></i>
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default BackPinfo;