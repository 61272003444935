import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { STRAPI } from '../../utils/config';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, Col, Row, Pagination } from 'react-bootstrap';

import './ProductList.css';

interface ProductListProps {
  products: any;
}

const ProductList: React.FC<ProductListProps> = ({ products }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);

  const handleCardClick = (productSlug: string) => {
    const urlSlugTmp = productSlug.replace(/\s+/g, '_');
    const urlSlug = urlSlugTmp.replace(/\//g, '__');
    const newPath = `${location.pathname}/${urlSlug}`;
    navigate(newPath);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const paginatedProducts = products.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const totalPages = Math.ceil(products.length / itemsPerPage);

  const renderPaginationItems = () => {
    const items = [];

    // Always show the first page
    if (currentPage > 2) {
      items.push(
        <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
          {1}
        </Pagination.Item>
      );
      if (currentPage > 3) {
        items.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }
    }

    // Determine the range of pages to display
    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(totalPages, currentPage + 1);

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
          {number}
        </Pagination.Item>
      );
    }

    // Always show the last page
    if (currentPage < totalPages - 1) {
      if (currentPage < totalPages - 2) {
        items.push(<Pagination.Ellipsis key="end-ellipsis" />);
      }
      items.push(
        <Pagination.Item key={totalPages} onClick={() => handlePageChange(totalPages)}>
          {totalPages}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <>
      <Row>
        {paginatedProducts.map((product: any) => {
          const { name_en, name_zh, image } = product.attributes;
          let imgUrl = "";

          if (image.data) {
            imgUrl = STRAPI + image.data.attributes.url;
          }

          return (
            <Col md={4} sm={6} className="d-flex align-items-stretch mt-3 mb-5" key={product.id}>
              <Card className="product-card shadow-sm" role="button" onClick={() => handleCardClick(name_en)}>
                <Card.Img className='pt-3' variant="top" src={imgUrl} alt={i18n.language === 'en' ? name_en : name_zh} />
                <Card.Body>
                  <Card.Text className="mt-3 text-center text-secondary">
                    <b>{i18n.language === 'en' ? name_en : name_zh}</b>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
      {totalPages > 1 && (
        <Pagination className="d-flex justify-content-center">
          <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
          {renderPaginationItems()}
          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
      )}
    </>
  );
}

export default ProductList;