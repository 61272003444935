import { STRAPI, STRAPI_token } from '../utils/config';
import axios from 'axios';

interface DBResult {
  info: any;
  error?: any;
}

interface UpdateItem {
  id: number;
  sort: number;
}

export const DBupsort = async (DBname: string, updateItems: UpdateItem[]): Promise<DBResult> => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${STRAPI_token}`
      }
    };

    const updatePromises = updateItems.map(item => {
      const url = `${STRAPI}/api/${DBname}/${item.id}`;
      return axios.put(url, { data: { sort: item.sort } }, config);
    });

    await Promise.all(updatePromises);

    return { info: 200 };
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      let errMSG = "";
      if (errMSG === "This attribute must be unique") {
        errMSG = "已有重複內容。";
      }
      return { info: err.response.status, error: errMSG };
    }
    console.error('Error setting up sync with remote database:', err);
    return { info: 500, error: '同步設置失敗' };
  }
};