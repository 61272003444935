import { DBsearch } from "../api/DBsearch";

export const ProductCateTrans = async (name:string, language:string) => {
    try {
        const searchStr = { name_en: { type: 'basic', operator: 'eq', text: name} };
        const res = await DBsearch('systems', 'populate=*', searchStr);
        return language === 'en' ? res.info[0].attributes.name_en : res.info[0].attributes.name_zh;
    } catch (error) {
        console.error('Fetching category name failed:', error);
        return null;
    }
};

export const ProductNameTrans = async (name:string, language:string) => {
    try {
        const searchStr = { name_en: { type: 'basic', operator: 'eq', text: name} };
        const res = await DBsearch('products', 'populate=*', searchStr);
        return language === 'en' ? res.info[0].attributes.name_en : res.info[0].attributes.name_zh;
    } catch (error) {
        console.error('Fetching product name failed:', error);
        return null;
    }
};
