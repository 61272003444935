import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Navbar, Nav, Button, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom'; 
import './NavbarFront.css';
import { DBsearch } from '../../api/DBsearch';
import styles from './NavbarFront.module.css'; 

const NavbarFront: React.FC = () => {
  // 正确地从 useTranslation 中解构出 t 函数
  const { t, i18n } = useTranslation(); 
  const location = useLocation(); 
  const [activeKey, setActiveKey] = useState<string | undefined>(undefined);
  const [categories, setCategories] = useState<any[]>([]);

  const currentLanguage = i18n.language;
  const toggleLanguage = (): void => {
    const newLanguage = currentLanguage === 'en' ? 'zh' : 'en';
    i18n.changeLanguage(newLanguage);
  };

  const buttonLabel = currentLanguage === 'en' ? '中文' : 'English';
  
  const showCategory = async()=>{
    try {
      const searchStr = { nonDel: { type: 'basic', operator: 'eq', text: true },display:{type: 'basic', operator: 'eq', text: true} };
      const res = await DBsearch('systems','populate=*',searchStr, 'sort_ASC');
      setCategories(res.info);
    } catch (error) {
      console.error('Fetching categories failed:', error);
    }

  }

  useEffect(() => {
    showCategory();
    switch(location.pathname) {
      case '/':
        setActiveKey('home');
        break;
      case '/about':
        setActiveKey('about');
        break;
      case '/product':
        setActiveKey('product');
        break;
      case '/contact':
        setActiveKey('contact');
        break;
      case '/download':
        setActiveKey('download');
        break;
      default:
        setActiveKey(undefined); 
        break;
    }
  }, [location.pathname,setCategories]);

  return (
    
    <Navbar expand="lg" bg="dark" variant="dark" className="navbar-custom shadow">
      <Container fluid>
        <Navbar.Brand href="/">
          <img src="/images/logo.png" height="40" alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-collapse" />
        <Navbar.Collapse id="navbar-collapse" className="justify-content-end">
          <Nav activeKey={activeKey}>
            <LinkContainer to="/">
              <Nav.Link eventKey="home" className={`px-3 ${styles.navLink} ${activeKey === 'home' ? `${styles.activeLink}` : ''}`} style={{ fontSize: '20pt' }} >
                {t('Home')}
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/about">
              <Nav.Link eventKey="about" className={`px-3 ${styles.navLink} ${activeKey === 'about' ? `${styles.activeLink}` : ''}`}>
                {t('About Us')}
              </Nav.Link>
            </LinkContainer>
            {/* <NavDropdown title={t('Product')} id="nav-dropdown-product" className={`${styles.navItem} ${styles.dropdownMenu}`}> */}
            <NavDropdown title={t('Product')} id="nav-dropdown-product" className={`${styles.navItem}`}>
              {categories.map((value, index) => {
                const { name_en, name_zh } = value.attributes;
                const urlName = name_en.replace(/\s+/g, '_');

                return (
                  <LinkContainer to={`/product/${urlName}`} key={index}>
                    <NavDropdown.Item eventKey={`product-category-${index}`} className={`${styles.dropdownItem}`}>
                      {i18n.language === 'en' ? name_en : name_zh}
                    </NavDropdown.Item>
                  </LinkContainer>
                );
              })}
            </NavDropdown>
            <LinkContainer to="/contact">
              <Nav.Link eventKey="contact" className={`px-3 ${styles.navLink} ${activeKey === 'contact' ? `${styles.activeLink}` : ''}`}>
                {t('Contact Us')} 
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/download">
              <Nav.Link eventKey="download" className={`px-3 ${styles.navLink} ${activeKey === 'download' ? `${styles.activeLink}` : ''}`}>
                {t('Download')} 
              </Nav.Link>
            </LinkContainer>
          </Nav>
          <Button className={`px-3 mx-2 ${styles.languageToggleBtn}`} variant="light" onClick={toggleLanguage}>
            {buttonLabel}
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>

  );
}

export default NavbarFront;
