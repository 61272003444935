import React, { useState, useEffect } from 'react';
import { Container, Col, Card, Row, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { DBsearch } from '../../api/DBsearch';
import { DBupdate } from '../../api/DBupdate';
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import { dataTableLang } from '../../utils/dataTableLang';
import { DBuploadDelMulti } from '../../api/back/DBuploadDelMulti';
import { DBupsort } from '../../api/DBupsort';
import { FaArrowAltCircleUp,FaArrowAltCircleDown } from "react-icons/fa";

interface AboutInfo {
  id: number;
  attributes: {
    title_en: string;
    title_zh: string;
    display: boolean;
    sort:number;
    image: {
      data: Array<{ id: number }>;
    } | null;
  };
}

const BackAbout: React.FC = () => {
  const [data, setData] = useState<AboutInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const showData = async () => {
    try {
      const searchStr = { nonDel: { type: 'basic', operator: 'eq', text: true } };
      const res = await DBsearch('introduces', 'populate=*', searchStr, 'sort_ASC');
      if (res.info.length > 0) {
        setData(res.info);
      }
    } catch (error) {
      console.error('Fetching product name failed:', error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const editMode = (id?: number) => {
    if (id) {
      navigate(`/dashboard/backAboutEdit/`, { state: { id } });
    } else {
      navigate(`/dashboard/backAboutEdit/`);
    }
  };

  const imgUpload = (id?: number) => {
    if (id) {
      navigate(`/dashboard/backAboutFileUp/`, { state: { id } });
    }
  };

  const deleteMode = async (id: number, imgData?: number[]) => {
    Swal.fire({
      title: '您確定要刪除這個項目嗎？',
      text: "此操作無法撤銷！",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '是的，刪除它！',
      cancelButtonText: '取消'
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (imgData !== undefined) {
          const deleteRes = await DBuploadDelMulti(imgData);
          if (!deleteRes.status) {
            throw new Error(deleteRes.message);
          }
        }
        const updateStr = {
          data: {
            nonDel: false
          }
        };
        const res = await DBupdate('introduces', id, updateStr);
        if (res.info === 200) {
          Swal.fire({
            icon: "success",
            title: "更新成功",
            showConfirmButton: false,
            timer: 1500
          });
          showData();
        }
      }
    });
  };

  const moveItem = async (index: number, direction: 'up' | 'down') => {
    const items = Array.from(data);
    const [movedItem] = items.splice(index, 1);
    if (direction === 'up') {
      items.splice(index - 1, 0, movedItem);
    } else {
      items.splice(index + 1, 0, movedItem);
    }

    const storedLists = items.map((item, idx) => ({
      id: item.id,
      sort: idx + 1
    }));

    try {
      await DBupsort('introduces', storedLists);
      showData();
      Swal.fire({
        icon: "success",
        title: "排序更新成功",
        showConfirmButton: false,
        timer: 1500
      });
    } catch (error) {
      console.error('Error updating product order:', error);
      Swal.fire({
        icon: "error",
        title: "排序更新失敗",
        showConfirmButton: false,
        timer: 1500
      });
    }
  };

  useEffect(() => {
    showData();
  }, []);

  useEffect(() => {
    if (!loading) {
      const table = $('#example_table').DataTable({
        ordering: false,
        language: dataTableLang,
        searching: false,
      });

      $('div.dt-length').hide();

      return () => {
        table.destroy();
      };
    }
  }, [loading, setData]);

  return (
    <>
      <Container fluid>
        <Col xs={12} lg={12}>
          <h4 className='text-secondary'><b>關於我們</b></h4>
          <p className='text-secondary'>編輯或填寫公司資料</p>
        </Col>
      </Container>
      <Container className='pt-2' fluid>
        <Card className='border shadow-sm bg-light'>
          <Card.Body>
            <Row>
              <Col xs={12}>
                <Button variant='outline-dark' className="px-4" onClick={() => editMode()}>+&ensp;新增公司資料</Button>
              </Col>
            </Row>
            <Row className='text-dark'>
              <Col xs={12}>
                <table id="example_table" className="table table-bordered " style={{ width: "100%" }}>
                  <thead>
                    <tr className='table-dark'>
                      <th></th>
                      <th>中文名稱</th>
                      <th>英文名稱</th>
                      <th className='text-center'>排序</th>
                      <th className='text-center'>呈現狀態</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading && data.length > 0 && data.map((info: AboutInfo, index: number) => {
                      const id = info.id;
                      const { title_en, title_zh, display, image,sort } = info.attributes;
                      let imgData: number[] | undefined;
                      if (image?.data) {
                        imgData = image.data.map((item) => item.id);
                      }
                      return (
                        <tr key={id}>
                          <td>
                            <Button variant='outline-light'  disabled={index === 0}  onClick={() => moveItem(index, 'up')}>
                              {
                                index==0 ? (
                                  <i className="bi bi-arrow-up-circle-fill" style={{color:'#BBBBBB',fontSize:'1.25rem'}}></i>
                                ):(
                                  <i className="bi bi-arrow-up-circle-fill" style={{color:'#5B91C4',fontSize:'1.25rem'}}></i>
                                )
                              }
                            </Button>
                            &ensp;
                            <Button variant='outline-light'  disabled={index === data.length - 1}  onClick={() => moveItem(index, 'down')}>
                              
                              {
                                index === data.length - 1 ? (
                                  <i className="bi bi-arrow-down-circle-fill" style={{color:'#BBBBBB',fontSize:'1.25rem'}}></i>
                                ):(
                                  <i className="bi bi-arrow-down-circle-fill" style={{color:'#FF5733',fontSize:'1.25rem'}}></i>
                                )
                              }
                            </Button>
                          </td>
                          <td>{title_zh}</td>
                          <td>{title_en}</td>
                          <td className='text-center'>{sort}</td>
                          <td className={`text-center ${display ? 'text-dark' : 'text-danger'}`}>
                            {display ? "開啟" : "關閉"}
                          </td>
                          <td className='text-center'>
                            <Button variant='light' className='shadow-sm' onClick={() => imgUpload(id)}>
                              <i className="bi bi-upload"></i>&ensp;圖片上傳
                            </Button>
                          </td>
                          <td className='text-center' >
                            <Button variant='outline-primary' onClick={() => editMode(id)}>
                              <i className="bi bi-highlighter"></i>
                            </Button>&emsp;
                            <Button variant='outline-danger' onClick={() => deleteMode(id, imgData)}>
                              <i className="bi bi-trash"></i>
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default BackAbout;