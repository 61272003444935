import React, { useState, useEffect } from 'react';
import { Container, Col, Card, Row, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { DBsearch } from '../../api/DBsearch';
import { DBupdate } from '../../api/DBupdate';
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import { dataTableLang } from '../../utils/dataTableLang';
import { DBuploadDel } from '../../api/back/DBuploadDel';
import { DBupsort } from '../../api/DBupsort';

interface Category {
  id: number;
  attributes: {
    name_en: string;
    name_zh: string;
    display: boolean;
    sort: number;
    image: {
      data: { id: number };
    } | null;
  };
}

const BackPtype: React.FC = () => {
  const [data, setData] = useState<Category[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const showData = async () => {
    try {
      const searchStr = { nonDel: { type: 'basic', operator: 'eq', text: true } };
      const res = await DBsearch('systems', 'populate=*', searchStr, 'sort_ASC');
      if (res.info.length > 0) {
        setData(res.info);
      }
    } catch (error) {
      console.error('Fetching product name failed:', error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const editMode = (id?: number) => {
    if (id) {
      navigate(`/dashboard/backPEdit/`, { state: { id, dbName: 'systems' } });
    } else {
      navigate(`/dashboard/backPEdit/`, { state: { dbName: 'systems' } });
    }
  };

  const deleteMode = async (id: number, imgId?: number) => {
    Swal.fire({
      title: '您確定要刪除這個項目嗎？',
      text: "此操作無法撤銷！",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '是的，刪除它！',
      cancelButtonText: '取消'
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (imgId) {
          const deleteRes = await DBuploadDel(imgId);
          if (!deleteRes.status) {
            throw new Error(deleteRes.message);
          }
        }

        const updateStr = {
          data: {
            products: [],
            nonDel: false
          }
        };
        const res = await DBupdate('systems', id, updateStr);
        if (res.info === 200) {
          Swal.fire({
            icon: "success",
            title: "更新成功",
            showConfirmButton: false,
            timer: 1500
          });
          showData();
        }
      }
    });
  };

  const moveCategory = async (index: number, direction: 'up' | 'down') => {
    const items: Category[] = Array.from(data);
    const [movedItem] = items.splice(index, 1);
    if (direction === 'up') {
      items.splice(index - 1, 0, movedItem);
    } else {
      items.splice(index + 1, 0, movedItem);
    }
    setData(items);

    const storedLists = items.map((item, idx) => ({
      id: item.id,
      sort: idx + 1
    }));

    try {
      await DBupsort('systems', storedLists);
      showData();
      Swal.fire({
        icon: "success",
        title: "排序更新成功",
        showConfirmButton: false,
        timer: 1500
      });
    } catch (error) {
      console.error('Error updating category order:', error);
      Swal.fire({
        icon: "error",
        title: "排序更新失敗",
        showConfirmButton: false,
        timer: 1500
      });
    }
  };

  useEffect(() => {
    showData();
  }, []);

  useEffect(() => {
    if (!loading) {
      const table = $('#example_table').DataTable({
        ordering: false,
        language: dataTableLang,
        searching: false,
      });

      $('div.dt-length').hide();

      return () => {
        table.destroy();
      };
    }
  }, [loading, setData]);

  return (
    <>
      <Container fluid>
        <Col xs={12} lg={12}>
          <h4 className='text-secondary'><b>類別資料</b></h4>
          <p className='text-secondary'>需要先填寫產品類別，才可以在產品資料綁定類別資料</p>
        </Col>
      </Container>
      <Container className='pt-2' fluid>
        <Card className='border shadow-sm bg-light'>
          <Card.Body>
            <Row>
              <Col xs={12}>
                <Button variant='outline-dark' className="px-4" onClick={() => editMode()}>+&ensp;新增類別資料</Button>
              </Col>
            </Row>
            <Row className='text-dark'>
              <Col xs={12}>
                <table id="example_table" className="table table-bordered " style={{ width: "100%" }}>
                  <thead>
                    <tr className='table-dark'>
                      <th></th>
                      <th>中文名稱</th>
                      <th>英文名稱</th>
                      <th className='text-center'>排序</th>
                      <th className='text-center'>呈現狀態</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading && data.length > 0 && data.map((info: Category, index: number) => {
                      const id = info.id;
                      const { name_en, name_zh, display, image, sort } = info.attributes;
                      let imgId: number | undefined;
                      if (image?.data) {
                        imgId = image.data.id;
                      }

                      return (
                        <tr key={id}>
                          <td>
                            <Button variant='outline-light' disabled={index === 0} onClick={() => moveCategory(index, 'up')}>
                              {index === 0 ? (
                                <i className="bi bi-arrow-up-circle-fill" style={{ color: '#BBBBBB', fontSize: '1.25rem' }}></i>
                              ) : (
                                <i className="bi bi-arrow-up-circle-fill" style={{ color: '#5B91C4', fontSize: '1.25rem' }}></i>
                              )}
                            </Button>
                            &ensp;
                            <Button variant='outline-light' disabled={index === data.length - 1} onClick={() => moveCategory(index, 'down')}>
                              {index === data.length - 1 ? (
                                <i className="bi bi-arrow-down-circle-fill" style={{ color: '#BBBBBB', fontSize: '1.25rem' }}></i>
                              ) : (
                                <i className="bi bi-arrow-down-circle-fill" style={{ color: '#FF5733', fontSize: '1.25rem' }}></i>
                              )}
                            </Button>
                          </td>
                          <td>{name_zh}</td>
                          <td>{name_en}</td>
                          <td className='text-center'>{sort}</td>
                          <td className={`text-center ${display ? 'text-dark' : 'text-danger'}`}>
                            {display ? "開啟" : "關閉"}
                          </td>
                          <td className='text-center'>
                            <Button variant='outline-primary' onClick={() => editMode(id)}>
                              <i className="bi bi-highlighter"></i>
                            </Button>&emsp;
                            <Button variant='outline-danger' onClick={() => deleteMode(id, imgId)}>
                              <i className="bi bi-trash"></i>
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default BackPtype;