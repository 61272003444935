import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { Container, Col, Card, Form, Button, Row, Spinner } from 'react-bootstrap';
import { DBuploadMulti } from '../../api/back/DBuploadMulti';
import { DBsearch } from '../../api/DBsearch';
import { DBupdate } from '../../api/DBupdate';
import Swal from 'sweetalert2';
import { STRAPI } from '../../utils/config';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import { dataTableLang } from '../../utils/dataTableLang';
import { DBuploadDel } from '../../api/back/DBuploadDel';

const BackAboutFileUp: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadStatus, setUploadStatus] = useState<string>('');
  const [isUploading, setIsUploading] = useState<boolean>(false); // New state for loading
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [slides, setSlides] = useState<any>([]);
  
  const { state } = location as any;
  const id = state?.id;

  const handleBack = () => {
    navigate('/dashboard/backAbout');
  }

  const handleFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFiles(Array.from(event.target.files));
    }
  };

  const deleteMode = async (imgId: number) => {
    try {
      /*const updatedImgData = data.map((item: any) => {
        if (item.id !== imgId) {
          return item.id;
        }
      }).filter((item: any) => item !== undefined);*/
      const updatedImgData = data.filter((item: any) => item.id !== imgId);

      const deleteRes = await DBuploadDel(imgId);
      if (!deleteRes.status) {
        throw new Error(deleteRes.message);
      }

      await DBupdate('introduces', id, { data: { image: updatedImgData } });

      Swal.fire({
        icon: "success",
        title: "success",
        text: "刪除成功",
        showConfirmButton: false,
        timer: 1500
      });

      showInfo(id);
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "刪除失敗",
        showConfirmButton: false,
        timer: 1500
      });
    }
  }

  const handleOpenLightbox = (index:number) => {
    setSlides(data.map((info:any) => ({ src: `${STRAPI}${info.attributes.url}` })));
    setOpen(true);
  };

  const showInfo = async (id: number) => {
    try {
      const searchStr = { id: { type: 'basic', operator: 'eq', text: id } };
      const res: any = await DBsearch('introduces', 'populate=*', searchStr);
      const { image } = res.info[0].attributes;
      if(image.data && image.data.length>0){
        setData(image.data);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error('Fetching product name failed:', error);
    } finally {
      setLoading(false);
    }
  }

  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      setUploadStatus('No files selected');
      return;
    }

    setIsUploading(true); // Set loading state to true

    const renamedFiles = selectedFiles.map(file => {
      const date = new Date();
      const formattedDate = `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, '0')}${String(date.getDate()).padStart(2, '0')}${String(date.getHours()).padStart(2, '0')}${String(date.getMinutes()).padStart(2, '0')}${String(date.getSeconds()).padStart(2, '0')}`;
      const newFileName = `introduces${id}_${formattedDate}_${file.name}`;
      return new File([file], newFileName, { type: file.type });
    });

    const formData = new FormData();
    renamedFiles.forEach(file => {
      formData.append('files', file);
    });
    formData.append('path', 'introduces');

    try {
      const res = await DBuploadMulti(formData);
      if (res.status === true) {
        const newImgData = res.info.map((file: { id: any; }) => file.id);
        const oldImgData = data.map((item: any) => item.id);
        const uploadFiles = [...oldImgData, ...newImgData];

        await DBupdate('introduces', id, { data: { image: uploadFiles } });

        Swal.fire({
          icon: "success",
          title: "success",
          text: "上傳成功",
          showConfirmButton: false,
          timer: 1500
        });

        // Clear documents
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        setSelectedFiles([]);
        showInfo(id);
      }
    } catch (error) {
      console.error('Error:', error);
      setUploadStatus('上傳有誤');
    } finally {
      setIsUploading(false); // Set loading state to false
    }
  };

  useEffect(() => {
    if (id) {
      showInfo(id);
    }
  }, [id]);

  useEffect(() => {
    if (!loading) {
      const table = $('#example_table').DataTable({
        ordering: false,
        language: dataTableLang,
        searching: false,
        pageLength: 3,
      });
      
      $('div.dt-length').hide();

      return () => {
        table.destroy();
      };
    }
  }, [loading, setData]);

  return (
    <>
      <Container fluid>
        <Col xs={12} lg={2}>
          <h4 className='text-secondary'><b>關於我們 / 圖片上傳</b></h4>
        </Col>
      </Container>
      <Container className='pt-2' fluid>
        <Card className='border shadow-sm bg-light'>
          <Card.Body>
            <Row>
              <Col xs={6}>
                <button type="button" onClick={() => handleBack()} aria-label="Go back"
                  style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }} >
                  <IoIosArrowDropleftCircle size={30} style={{ color: 'rgba(0,0,0,0.3)' }} />
                </button>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col md={10}>
                <Form.Group controlId="formFiles" >
                  <Form.Label className='text-dark'><b>選擇上傳圖片</b></Form.Label>
                  <Form.Control type="file" ref={fileInputRef} multiple onChange={handleFilesChange} />
                </Form.Group>
                <p className='text-secondary'>建議上傳檔案為.jpg 或 .png。因應前台版面所示，圖片比例建議為534x400。</p>
                <p className='text-danger'>{uploadStatus}</p>
              </Col>
              <Col md={2} className='text-center mt-4 pt-2'>
                <Form.Group controlId="formFiles" >
                  <Form.Label className='text-dark'></Form.Label>
                  <Button 
                    variant="outline-dark" 
                    className='w-100 p-1' 
                    onClick={handleUpload}
                    disabled={isUploading} // Disable button while uploading
                  >
                    {isUploading ? <Spinner as="span" animation="border" size="sm" /> : '送出'}
                  </Button>
                </Form.Group>
              </Col>
            </Row>
            <Row className='text-dark'>
              <Col xs={12}>
                <table id="example_table" className="table table-bordered " style={{ width: "100%" }}>
                  <thead>
                    <tr className='table-dark text-center'>
                      <th className='col-11'>(點選圖片可放大查看)</th>
                      <th className='col-1'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading &&data.length > 0 ? (
                      data.map((info: any, index: number) => {
                        const id = info.id;
                        const { url } = info.attributes;
                        //const { url } = info.attributes.formats.small;
                        return (
                          <tr key={index}>
                            <td className='text-center align-middle' role='button'>
                              <img src={`${STRAPI}${url}`} className="img-fluid fixed-size" onClick={() => handleOpenLightbox(index)} alt="關於我們公司照片" />
                            </td>
                            <td className='text-center align-middle'>
                              <Button variant="outline-danger" onClick={() => deleteMode(id)}>
                                <i className="bi bi-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="text-center text-secondary">
                          目前尚未有圖片上傳
                        </td>
                        <td className="text-center text-secondary">—</td>
                      </tr>
                    )}
                  </tbody>
                  {
                    data.length>0 && <Lightbox open={open} close={() => setOpen(false)} slides={slides} />
                  }
                </table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default BackAboutFileUp;